<template>
  <div>
    <div style="display: flex; flex-direction: column">
      <div>
        <driver-transactions-searcher></driver-transactions-searcher>
      </div>
      <div style="flex: 1">
        <driver-transactions-search-result></driver-transactions-search-result>
      </div>
    </div>
  </div>
</template>

<script>
import DriverTransactionsSearcher from "@/components/transactions/DriverTransactionsSearcher.vue";
import DriverTransactionsSearchResult from "@/components/transactions/DriverTransactionsSearchResult.vue";

export default {
  components: {
    DriverTransactionsSearcher,
    DriverTransactionsSearchResult
  },
  mounted() {
    this.$store.commit("transactions/clear");
  }
};
</script>
