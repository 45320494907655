<style scoped>
.modal {
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}
.modal-content-img {
  margin: auto;
  display: block;
  width: 80%;
  max-width: 700px;
  border: 0;
  animation-name: zoom;
  animation-duration: 0.3s;
}

@keyframes zoom {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}
.close {
  position: absolute;
  top: 15px;
  right: 35px;
  color: #f1f1f1;
  font-size: 40px;
  font-weight: bold;
  transition: 0.3s;
}
.close:hover,
.close:focus {
  color: #bbb;
  text-decoration: none;
}
@media only screen and (max-width: 700px) {
  .modal-content-img {
    width: 100%;
  }
}
</style>

<template>
  <div v-if="showModal" class="modal">
    <span @click="close" class="close cursor-pointer">&times;</span>
    <b-img @click="close" :src="image" class="modal-content-img"></b-img>
  </div>
</template>

<script>
import api from "@/services/api.js";
import { OPEN_IMAGE_MODAL } from "@/const/events.js";

export default {
  data() {
    return {
      showModal: false,
      image: ""
    };
  },
  methods: {
    openImageModal({ imageId }) {
      api.getGoodsImage(imageId).then(resp => {
        this.image = "data:application/octet-stream;base64," + new Buffer(resp.data, "binary").toString("base64");
        this.showModal = true;
      });
    },
    close() {
      this.showModal = false;
      this.image = "";
    }
  },
  mounted() {
    this.$bus.on(OPEN_IMAGE_MODAL, this.openImageModal);
  },
  beforeDestroy() {
    this.$bus.off(OPEN_IMAGE_MODAL, this.openImageModal);
  }
};
</script>
