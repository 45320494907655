<template>
  <div>
    <b-row class="mb-2">
      <b-col>
        <b-form-datepicker
          :value="filters.createdTo"
          :locale="this.$i18n.locale"
          @input="updateFilter('createdTo', $event)"
        ></b-form-datepicker>
      </b-col>
    </b-row>
    <b-row class="mb-2">
      <b-col>
        <b-overlay :show="areDriversLoading">
          <v-select
            :value="filters.driverId"
            :clearable="false"
            :options="availableDrivers"
            label="userName"
            @input="updateFilter('driver', $event)"
            :placeholder="$t('transactions.driver-searcher.driver-select-placeholder')"
          >
            <template v-slot:no-options>{{ $t("vselect.noOptions") }}</template>
          </v-select>
        </b-overlay>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import api from "@/services/api.js";

import { DRIVER } from "@/const/user-role.js";

export default {
  data() {
    let lastDayOfPreviousMonth = new Date();
    lastDayOfPreviousMonth.setDate(1);
    lastDayOfPreviousMonth.setHours(-1);
    return {
      areDriversLoading: false,
      availableDrivers: [],
      selectedDriver: null,
      createdTo: lastDayOfPreviousMonth.toISOString().slice(0, 10)
    };
  },
  computed: {
    filters() {
      return this.$store.getters["transactions/filters"];
    }
  },
  methods: {
    updateFilter(property, value) {
      this.$store.dispatch("transactions/setFilter", { property, value });
    }
  },
  mounted() {
    this.areDriversLoading = true;
    api
      .getUsersNamesByRole(DRIVER)
      .then(resp => (this.availableDrivers = resp.data))
      .catch(() => alert(this.$t("transactions.driver-searcher.error.general")))
      .finally(() => (this.areDriversLoading = false));
  }
};
</script>
