<style>
.checkbox {
  text-align: center;
}
</style>

<template>
  <b-overlay :show="$store.state.transactions.isLoading">
    <b-row class="mb-3">
      <b-col>
        <b-button v-b-modal.close-transactions-modal :disabled="!anyFilteredTransactionsSelected">
          {{ $t("transactions.driver-result.close-btn-caption") }}
        </b-button>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-pagination
          :value="pagination.currentPage"
          :total-rows="pagination.totalCount"
          :per-page="pagination.limit"
          @input="onChangeCurrentPage"
        ></b-pagination>
        <b-table
          show-empty
          :fields="fields"
          :items="filteredTransactions"
          @head-clicked="onTableHeadClicked"
          @sort-changed="onSortChanged"
          :empty-text="$t('transactions.driver-result.table.empty-text')"
          responsive
          no-local-sorting
        >
          <template slot="top-row">
            <td></td>
            <td></td>
            <td>
              <b-input :value="filters.goods" @change="onUpdateFilter('goods', $event)"></b-input>
            </td>
            <td>
              <b-datepicker
                reset-button
                :value="filters.createdAt"
                :locale="$i18n.locale"
                :label-reset-button="$t('datepicker.reset-btn')"
                @input="onUpdateFilter('createdAt', $event)"
                placeholder=""
                label-no-date-selected=""
                boundary="window"
              ></b-datepicker>
            </td>
            <td>
              <b-input :value="filters.count" @change="onUpdateFilter('count', $event)"></b-input>
            </td>
          </template>
          <template v-slot:cell(selected)="row">
            <b-form-checkbox
              class="checkbox"
              size="lg"
              v-model="row.item.selected"
              @input="onSelectTransaction($event, row.item.id)"
            ></b-form-checkbox>
          </template>
          <template v-slot:cell(createdAt)="row">
            {{ $d(Date.parse(row.item.createdAt)) }}
          </template>
          <template v-slot:cell(goodsInfo)="row">
            {{ row.item.goodsInfo.title[$i18n.locale] }}
          </template>
          <template v-slot:cell(thumbnailImage)="row">
            <thumbnail-image :imageId="row.item.goodsInfo.imageId" />
          </template>
        </b-table>
        <image-modal />
      </b-col>
    </b-row>
    <b-modal
      id="close-transactions-modal"
      @ok.prevent="closeTransactions"
      :title="$t('transactions.driver-result.modal.title')"
      :ok-title="$t('transactions.driver-result.modal.ok-btn-caption')"
      :cancel-title="$t('transactions.driver-result.modal.cancel-btn-caption')"
    >
      <template>
        {{ $t("transactions.driver-result.modal.body") }}
      </template>
    </b-modal>
  </b-overlay>
</template>

<script>
import api from "@/services/api.js";
import ImageModal from "@/components/goods-images/ImageModal.vue";
import ThumbnailImage from "@/components/goods-images/ThumbnailImage.vue";

export default {
  components: {
    "image-modal": ImageModal,
    "thumbnail-image": ThumbnailImage
  },
  data() {
    return {
      selectAll: false
    };
  },
  computed: {
    filteredTransactions() {
      return this.$store.state.transactions.transactions;
    },
    selectedFilteredTransactions() {
      return this.filteredTransactions.filter(x => x.selected);
    },
    anyFilteredTransactionsSelected() {
      return this.selectedFilteredTransactions.length > 0;
    },
    fields() {
      return [
        { key: "selected", label: "", sortable: false },
        { key: "thumbnailImage", label: "", sortable: false },
        { key: "goodsInfo", label: this.$t("transactions.driver-result.table.goods-info"), sortable: true },
        { key: "createdAt", label: this.$t("transactions.driver-result.table.created-at"), sortable: true },
        { key: "sold", label: this.$t("transactions.driver-result.table.sold"), sortable: true }
      ];
    },
    pagination() {
      return this.$store.getters["transactions/pagination"];
    },
    filters() {
      return this.$store.getters["transactions/filters"];
    }
  },
  methods: {
    onTableHeadClicked(column) {
      if (column == "selected") {
        this.selectAll = !this.selectAll;
        this.$store.commit("transactions/setSelectedState", {
          transactionIds: this.filteredTransactions.map(x => x.id),
          isSelected: this.selectAll
        });
      }
    },
    onSelectTransaction(evt, id) {
      this.$store.commit("transactions/setSelectedState", {
        transactionIds: [id],
        isSelected: evt
      });
    },
    closeTransactions() {
      let transactionsToCloseIds = this.selectedFilteredTransactions.map(x => x.id);
      api
        .closeTransactions(transactionsToCloseIds)
        .then(() => {
          this.$store.dispatch("transactions/loadTransactions");
          this.$bvModal.hide("close-transactions-modal");
        })
        .catch(() => alert(this.$t("transactions.driver-result.error.general")));
    },
    onChangeCurrentPage(page) {
      this.$store.dispatch("transactions/setCurrentPage", page);
    },
    onUpdateFilter(property, value) {
      this.$store.dispatch("transactions/setFilter", { property, value });
    },
    onSortChanged(ctx) {
      this.$store.dispatch("transactions/setSort", ctx);
    }
  },
  watch: {
    "$i18n.locale"() {
      this.onChangeCurrentPage(1);
    }
  }
};
</script>
